import React, { useState } from 'react'
import styles from './LazyImage.module.scss'

const LazyImage = ({ image, alt }) => {
    const [loaded, setLoaded] = useState(false);

    const showImage = () => {
        setLoaded(true);
    }

    return (
        <div className={styles.imageWrapper}>
            <img
                className={styles.lazyImage}
                alt={alt}
                src={`${image}`}
                style={loaded ? { display: 'none' } : {}}
            />
            <img
                className={styles.lazyImage}
                srcSet={`${image} 1x,
                ${image} 2x,
                ${image} 3x`}
                src={`${image}`}
                alt={alt}
                onLoad={showImage}
                style={loaded ? {} : { display: 'none' }}
            />
        </div>
    )
}

export default LazyImage