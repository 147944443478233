import React, { useEffect } from 'react';


function PayU(props) {
    const submitBtn = React.useRef(null);

    /* const PAYU_URL = process.env.REACT_APP_PAYU_URL;
    const PAYU_TEST = process.env.REACT_APP_PAYU_TEST; */
    const PAYU_URL = "https://checkout.payulatam.com/ppp-web-gateway-payu/"
    // const PAYU_URL = "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/"
    // const PAYU_URL_CONFIRMACION = `${process.env.REACT_APP_SERVER_URL}${props?.urlConfirmationPayU}`;
    const PAYU_URL_CONFIRMACION = `https://app.ecometri.store/api/v1/notification/payu/53fc70c7-021a-4f92-91d5-f14ab4cbab77`;

    useEffect(() => {
        submitBtn.current.click()
        /* if (props.typePay === 'payu') {
            submitBtn.current?.click();
        } */
    }, [props.typePay]);

    return (
        <>
            {/* <form style={{ width: '0px', height: '0px' }} action={props.testPayU ? PAYU_TEST_URL : PAYU_URL} target={props.newTab ? '_blank' : ''} method='post'> */}
            <form style={{ width: '0px', height: '0px' }} action={PAYU_URL} target={props.newTab ? '_blank' : ''} method='post'>
                <input type='hidden' name='accountId' defaultValue={props.idAccount} />
                <input type='hidden' name='merchantId' defaultValue={props.merchantId} />
                <input type='hidden' name='refVenta' defaultValue={props.refSale} />
                <input type='hidden' name='referenceCode' defaultValue={props.refSale} />
                <input type='hidden' name='reference_sale' defaultValue={props.refSale} />
                <input type='hidden' name='description' defaultValue={props.extra1} />
                <input type='hidden' name='extra1' defaultValue={props.extra1} />
                <input type='hidden' name='extra2' defaultValue={props.refSale} />
                <input type='hidden' name='amount' value={String(props.valueToPay)} />
                <input type='hidden' name='tax' defaultValue='0' />
                <input type='hidden' name='taxReturnBase' defaultValue='0' />
                <input type='hidden' name='signature' defaultValue={props.firma} />
                <input type='hidden' name='currency' defaultValue='COP' />
                {/* <input type='hidden' name='test' defaultValue={props.testPayU ? 1 : 0} /> */}
                <input type='hidden' name='test' defaultValue='0' />
                <input type='hidden' name='buyerEmail' defaultValue={props.email} />
                <input type='hidden' name='payerEmail' defaultValue={props.email} />
                <input type='hidden' name='mobilePhone' defaultValue={props.numberPhone} />
                <input type='hidden' name='buyerFullName' defaultValue={props.name} />
                <input name="shippingAddress" type="hidden" defaultValue={props.address} />
                <input name="shippingCity" type="hidden" defaultValue={props.city} />
                <input name="shippingCountry" type="hidden" defaultValue={props.country} />
                <input type='hidden' name='responseUrl' defaultValue={props.urlResponse} />
                <input type='hidden' name='confirmationUrl' defaultValue={PAYU_URL_CONFIRMACION} />
                <input ref={submitBtn} type='submit' name='Submit' value='Send' style={{ opacity: '0' }} />
            </form>

        </>
    );
}

export default PayU;
