import React from 'react'
import {
  Grid,
  Typography
} from '@material-ui/core'

import ShopBrokenLink from '../Icons/ShopBrokenLink'

import NotFoundStyles from './NotFound.module.scss'

const NotFound = ({ storeName }) => {
  return (
    <>
      <div className={NotFoundStyles.notFoundContainer}>
        <Grid
          className={NotFoundStyles.notFoundWrapper}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <div className={NotFoundStyles.notFound}>
              <ShopBrokenLink className={NotFoundStyles.notFoundIcon} />
              {storeName && storeName !== '404' ? <Typography variant="h5">
                Ups! Parece que {storeName.charAt(0).toUpperCase() + storeName.slice(1)} no es una tienda Ecometri existente.
              </Typography>
                : <Typography variant="h5">
                  Ups! Parece que ingresaste a una tienda Ecometri no existente.
                </Typography>}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default NotFound
