import React from "react";
import ProductMediaStyles from "./ProductMedia.module.scss";
import LazyImage from "../LazyImage/LazyImage";

const ProductMedia = ({ image, alt }) => {
  const image_src = JSON.stringify({
    bucket: "wa10x.v2",
    key: `${image}`,
    edits: {
      resize: {
        width: 1080,
        height: 1080, // Set fixed height to maintain aspect ratio
        fit: "contain", // Change to contain to prevent cropping
      },
      rotate: null,
    },
  });

  const productImage = image
    ? `${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(
        image_src
      ).toString("base64")}`
    : `/10x-product.jpg`;

  return (
    <div className={ProductMediaStyles.postWrapper}>
      <div className={ProductMediaStyles.imageContainer}>
        <figure className={ProductMediaStyles.productImage}>
          <LazyImage image={productImage} alt={alt} />
        </figure>
      </div>
    </div>
  );
};

export default ProductMedia;